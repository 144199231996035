import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
	name: 'language',
	initialState: 'pl',
	reducers: {
		setLang: (state, action) => action.payload,
	},
});

export const { setLang } = languageSlice.actions;

export default languageSlice.reducer;
