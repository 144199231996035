export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export function getRandomArbitrary(min, max) {
	return Math.random() * (max - min) + min;
}

export function getReversedIndex(index, length) {
	if (index === -1) {
		return -1;
	}
	return length - index - 1;
}

export const createServicesPrefix = (category, locale, servicePrefix) => {
	switch (category) {
		case 'cybersecurity':
			if (locale === 'pl') return `${servicePrefix}/cyberbezpieczenstwo`;
			return `${servicePrefix}/cyber-security`;
		case 'cloud':
			if (locale === 'pl') return `${servicePrefix}/chmura`;
			return `${servicePrefix}/cloud`;
		case 'network':
			if (locale === 'pl') return `${servicePrefix}/sieci-i-dc`;
			return `${servicePrefix}/network-dc`;
		case 'services':
			if (locale === 'pl') return `${servicePrefix}/uslugi`;
			return `${servicePrefix}/services`;
		default:
			return servicePrefix;
	}
};

export function findInterval(length, index, nominative = 4) {
	if (index < 0 || index >= length) {
		console.log('Index must be within the range of [0, length)');
	}
	const sectionSize = length / nominative;
	return Math.floor(index / sectionSize) + 1;
}
