import React from 'react';

function ArrowLongIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 20 20">
			<path d="M17.854 10.354a.5.5 0 000-.708l-3.182-3.182a.5.5 0 10-.708.708L16.793 10l-2.829 2.828a.5.5 0 10.708.708l3.182-3.182zM2.5 10.5h15v-1h-15v1z" />
		</svg>
	);
}

export default ArrowLongIcon;
