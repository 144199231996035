import React from 'react';
import styled from 'styled-components';

const StyledLoadMoreButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 30px;
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	font-weight: ${({ theme }) => theme.fontWeight.black};
	font-size: 13px;
	line-height: 16px;
	color: ${({ theme }) => theme.colors.white};
	margin: 65px 0;
	p {
		cursor: pointer;
		position: relative;

		&:before {
			background-color: ${({ theme }) => theme.colors.white};
			bottom: -2px;
			content: '';
			height: 1px;
			transform-origin: 50%;
			left: 0;
			position: absolute;
			transform: scaleX(0);
			transition: transform 0.3s ease-in-out;
			width: 100%;
		}
		&:hover {
			&:before {
				transform: scaleX(1);
				width: 100%;
			}
		}
	}
	span {
		color: ${({ theme }) => theme.colors.green};
	}
`;

const LoadMoreButton = ({ onLoadMore, text }) => {
	return (
		<StyledLoadMoreButtonWrapper>
			<p onClick={onLoadMore} className="load-more-btn">
				{text}
				<span>.</span>
			</p>
		</StyledLoadMoreButtonWrapper>
	);
};

export default LoadMoreButton;
