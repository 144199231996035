const themeSettings = {
	colors: {
		black: 'rgb(0,0,0)',
		white: 'rgb(255,255,255)',
		whiteAlpha60: 'rgba(255,255,255,0.6)',
		whiteAlpha52: 'rgba(255, 255, 255, 0.52)',
		navy: 'rgba(24, 26, 88, 1)',
		navyAlpha60: 'rgba(24, 26, 88, 0.6)',
		menuServicesColor: 'rgba(9, 7, 53, 1)',
		menuServicesBorderBottom: 'rgba(110, 110, 189, 1)',
		green: 'rgb(12, 237, 199, 1)',
		greenLight: 'rgba(134, 255, 235, 1)',
		grey: 'rgba(245, 245, 245, 1)',
		gradientPurple: 'rgba(151, 71, 255, 0.24)',
		footerGray: 'rgba(149, 149, 184, 1)',
		gradientGreen: 'rgba(101, 245, 219, 0.7)',
		gradientWhite: 'rgba(255, 255, 255, 0.9)',
		gradientGray: 'rgba(255, 255, 255, 0.3)',
		gradients: {
			topGreenToPurple: 'linear-gradient(174.06deg, rgba(101, 245, 219, 0.7) 0%, rgba(151, 71, 255, 0.24) 99.88%);',
			topWhiteToGray: 'linear-gradient(166.84deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.3) 92.19%);',
			leftWhiteToTransparent: 'linear-gradient(90.14deg, rgba(255, 255, 255, 0.53) 0.75%, transparent 14.39%)',
			leftBlueToDarkBlue: `radial-gradient(61.49% 193.87% at 6.56% 57.21%, #1912AF 0%, #0D0C35 100%)`,
		},
		spaceCategoryCaseStudy: 'rgba(237, 226, 66, 1)',
		spaceCategoryCaseStudyHover: 'rgba(255, 249, 159, 1)',
		spaceCategoryCompanyLife: 'rgba(114, 221, 223, 1)',
		spaceCategoryCompanyLifeHover: 'rgba(169, 244, 245, 1)',
		spaceCategoryPartner: 'rgba(151, 71, 255, 1)',
		spaceCategoryPartnerHover: 'rgba(187, 134, 255, 1)',
		spaceCategoryEvents: 'rgba(17, 14, 86, 1)',
		spaceCategoryEventsHover: 'rgba(10, 5, 133, 1)',
		spaceCategoryTagBar: 'rgba(255,255,255,0.44)',
		spaceCategoryTagBarHover: 'rgba(255, 255, 255, 0.44)',
		spaceCategoryTagPage: 'rgba(213, 213, 236, 1)',
		spaceCategoryTagPageHover: 'rgba(213, 213, 236, 0.44)',
		footerCloudBlue: 'rgba(42, 193, 215, 1)',
		footerNetworkViolet: 'rgba(151, 71, 255, 1)',
		footerServicesYellow: 'rgba(237, 226, 66, 1)',
		cybersecurity: 'rgb(12, 237, 199)',
		cloud: 'rgb(0, 148, 255)',
		services: 'rgb(237, 226, 66)',
		network: 'rgb(151, 71, 255)',

		// old colors
		whiteAlpha: 'rgba(255, 255, 255, 0.20)',
		primaryGreen: 'rgb(20,234,199)',
		primaryBlue: 'rgb(25,28,99)',
		primaryBlueAlpha: 'rgba(25,28,99, .65)',
		lightGreen: 'rgba(12,237,199)',
		lightGreenAlpha: 'rgba(12,237,199,0.24)',
		lightGreenCareerAlpha: 'rgba(12,237,199,0.78)',
		lightGreenMenuAlpha: 'rgba(12,237,199,0.65)',
		lightBlue: 'rgb(25,28,99,0.60)',
		lightBlueCareerAlpha: 'rgb(25,28,99,0.78)',
		tileLightBlue: 'rgb(30, 214, 220)',
		logoLightBlue: `rgb(45, 222, 235)`,
		logoDarkBlue: `rgb(55, 196, 212)`,
		greenBlue: `rgb(38, 93, 135)`,
		shapeLightGreenAlpha: 'rgba(101,245,219,0.24)',
		shapeLightBlueAlpha: 'rgba(42,193,215,0.81)',
		shapeDarkBlueAlpha: 'rgba(42,193,215,0.18)',
		red: 'rgb(214,48,47)',
	},
	fontFamily: {
		montserratFont: "'Montserrat', sans-serif",
		robotoFont: "'Roboto Condensed', sans-serif",
		bornaFont: "'Borna', sans-serif",
	},
	fontWeight: {
		extraLight: '200',
		light: '300',
		regular: '400',
		medium: '500',
		semiBold: '600',
		bold: '700',
		black: '900',
	},
	padding: {
		bigPadding: '0 140px',
		mediumPadding: '0 55px',
		smallPadding: '0 30px',
	},
	layers: {
		first: 10,
		second: 20,
		third: 30,
		fourth: 40,
		fifth: 50,
	},
	breakpoints: {
		smallTablet: 768,
		tablet: 1024,
		desktop: 1440,
	},
	device: {
		mobileS: `(min-width: 319.97px)`,
		mobileM: `(min-width: 374.97px)`,
		mobileL: `(min-width: 424.97px)`,
		mobileXL: `(min-width: 559.97px)`,
		tabletS: `(min-width: 699.97px)`,
		tablet: `(min-width: 767.97px)`,
		tabletLarge: `(min-width: 779.97px)`,
		tabletL: `(min-width: 859.97px)`,
		laptop: `(min-width: 1023.97px)`,
		laptopM: `(min-width: 1279.97px)`,
		laptopL: `(min-width: 1439.97px)`,
		desktop: `(min-width: 1919.97px)`,
		desktopL: `(min-width: 2559.97px)`,
	},
};

export default themeSettings;
