import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Configure, Highlight, Hits, Index, useHits, useStats } from 'react-instantsearch-hooks-web';
import { useDispatch } from 'react-redux';
import ArrowLink from '../../assets/images/arrow_link.svg';
import { setIsSearchBarOpened } from '../../state/searchBarSlice';

const StyledSearchResultsWrapper = styled.div`
	left: 0;
	margin-top: 30px;
	max-height: calc(var(--inner-height) - 120px);
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 25px;
	position: absolute;
	top: 70px;
	width: 100%;
	@media (min-width: 768px) {
		padding: 0;
		max-height: calc(var(--inner-height) - 40px - 40px - 100px);
	}
	@media (min-width: 1280px) {
		max-height: calc(var(--inner-height) - 40px - 40px - 100px);
		position: relative;
		top: unset;
		left: unset;
		margin-top: 0;
	}

	::-webkit-scrollbar {
		display: none;
	}
`;

const StyledPageHit = styled.div`
	background: transparent;
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;

	&:hover {
		@media (min-width: 1280px) {
			background: rgba(255, 255, 255, 0.22);
			img {
				opacity: 1;
				transition: opacity 0.05s ease-in-out;
			}
		}
	}

	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.regular} !important;
	line-height: 24px;
	padding: 0 5px;
	@media (min-width: 768px) {
		font-size: 30px;
		line-height: 40px;
	}
	@media (min-width: 1280px) {
		font-size: 34px;
		line-height: 50px;
	}
`;

const StyledLink = styled.div`
	position: relative;

	img {
		opacity: 0;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		transition: opacity 0.05s ease-in-out;
	}
`;

const StyledCategorySubtitle = styled.p`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	font-size: 12px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 50px;
	margin-top: 16px;
	text-transform: uppercase;
`;

function HitCount() {
	const { nbHits } = useStats();

	return nbHits > 0 ? (
		<div className="HitCount">
			{nbHits} result{nbHits !== 1 ? 's' : ''}
		</div>
	) : null;
}

const createServicesPrefix = (category, locale, servicePrefix) => {
	switch (category) {
		case 'cybersecurity':
			if (locale === 'pl') return `${servicePrefix}/cyberbezpieczenstwo`;
			return `${servicePrefix}/cyber-security`;
		case 'cloud':
			if (locale === 'pl') return `${servicePrefix}/chmura`;
			return `${servicePrefix}/cloud`;
		case 'network':
			if (locale === 'pl') return `${servicePrefix}/sieci-i-dc`;
			return `${servicePrefix}/network-dc`;
		case 'services':
			if (locale === 'pl') return `${servicePrefix}/uslugi`;
			return `${servicePrefix}/services`;
		default:
			return servicePrefix;
	}
};

function PageHit({ hit }) {
	const dispatch = useDispatch();
	const noResults = !hit;
	const slug = hit?.slug || '';
	const finalSlug = slug === 'strona-glowna' || slug === 'home' ? '' : slug;
	const category = hit?.category?.title || '';
	const locale = hit?.lang || 'pl';
	const knowledgePrefix = locale === 'pl' ? 'wiedza' : 'knowledge';
	const partnerSlug = hit?.partnerSlug || '';
	// const locale = 'pl';
	const servicePrefix = locale === 'pl' ? 'oferta' : 'offer';
	const productName = hit?.productName || '';
	const isProductPage = productName !== '';
	const prefix = isProductPage ? `${knowledgePrefix}` : createServicesPrefix(category, locale, servicePrefix);
	const slugWithoutPrefix = isProductPage ? `${partnerSlug}_${slug.trim()}` : `${slug.trim()}`;
	const path = locale === 'pl' ? `${prefix}/${slugWithoutPrefix}` : `en/${prefix}/${slugWithoutPrefix}`;
	const isSinglePage = category === '' && !isProductPage;
	const linkTo = isSinglePage ? finalSlug : path;
	const moduleHasDescription = hit?.chooseModule?.[1]?.description || false;
	const handleResultClick = () => {
		navigate(`/${linkTo}`);
		dispatch(setIsSearchBarOpened(false));
	};
	return (
		<>
			{/* {!noResults && ( */}
			<StyledPageHit onClick={() => handleResultClick()}>
				<StyledLink>
					<div>
						<Highlight attribute="chooseModule.description" hit={hit} />
					</div>
					<div>
						<Highlight attribute="singlePageName" hit={hit} />
					</div>
					<div>
						<Highlight attribute="productName" hit={hit} />
					</div>
					<div>
						<Highlight attribute="articleName" hit={hit} />
					</div>
					{!isProductPage && (
						<div>
							<Highlight attribute="name" hit={hit} />
						</div>
					)}
					<img src={ArrowLink} alt="arrow-link" />
				</StyledLink>
				{/* <Snippet attribute="excerpt" hit={hit} /> */}
			</StyledPageHit>
			{/* )} */}
			{/* {noResults && <h1>Brak wyników</h1>} */}
		</>
	);
}

function HitsInIndex({ index, hits }) {
	return (
		<Index indexName={index.name}>
			{/* <HitCount /> */}
			{/* <StyledCategorySubtitle>{index.title}:</StyledCategorySubtitle> */}
			<Hits className="Hits" hitComponent={PageHit} />
		</Index>
	);
}

function SearchResult({ indices, className, activeSite }) {
	const { hits } = useHits();
	const hasAnyHits = hits.length > 0;
	return (
		<StyledSearchResultsWrapper className={className}>
			<Configure filters={`lang:${activeSite}`} distinct />
			{/* {hasAnyHits && indices.map((index) => <HitsInIndex index={index} key={index.name} hits={hits} />)} */}
			{indices.map((index) => (
				<HitsInIndex index={index} key={index.name} hits={hits} />
			))}
			{/* {!hasAnyHits && <p style={{ marginBottom: 10 }}>{activeSite === 'pl' ? 'Brak wyników' : 'No results'}</p>} */}
			{/* <PoweredBy /> */}
		</StyledSearchResultsWrapper>
	);
}

export default SearchResult;
