import { createServicesPrefix } from './Utils';

export function getSlug(slug = '', locale = 'pl') {
	if (slug === 'strona-glowna' || slug === 'home') {
		return locale === 'pl' ? '/' : '/en';
	}
	return locale === 'pl' ? `/${slug.trim()}` : `/en/${slug.trim()}`;
}

export function getServicePageSlug(slug = null, category = null, locale = null) {
	if (!slug || !category || !locale) return '#';
	const prefix = createServicesPrefix(category, locale, locale === 'pl' ? 'oferta' : 'offer');
	return locale === 'pl' ? `/${prefix}/${slug.trim()}` : `/en/${prefix}/${slug.trim()}`;
}

export function getProductPageSlug(slug = '', partner = '', locale = 'pl') {
	return locale === 'pl' ? `/wiedza/${partner}_${slug.trim()}` : `/en/knowledge/${partner}_${slug.trim()}`;
}
