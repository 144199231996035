import React from 'react';
import styled from 'styled-components';
import ArrowLongIcon from '../../inlineSvg/ArrowLongIcon';

const Button = styled.button`
	align-items: center;
	border: unset;
	color: ${({ theme, color }) => theme.colors[color]};
	column-gap: 11px;
	cursor: pointer;
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	font-size: 14px;
	font-weight: ${({ theme, fontWeight }) => theme.fontWeight[fontWeight]};
	height: fit-content;
	padding: unset;
	position: relative;
	width: fit-content;

	svg {
		fill: ${({ theme, color, arrowColor }) => theme.colors[arrowColor || color]};
		transform: rotate(${({ reverse }) => (reverse ? '180deg' : '0deg')});
		transition: transform 0.25s ease-in-out;
		transform: ${({ reverse, isHovered }) =>
		isHovered ? (reverse ? 'translateX(-10px) rotate(180deg)' : 'translateX(10px) rotate(0deg)') : ''};
	}

	&:hover {
		svg {
			transform: ${({ reverse }) => (reverse ? 'translateX(-10px) rotate(180deg)' : 'translateX(10px) rotate(0deg)')};
		}
	}
`;

function GhostArrowButton({
	color = 'menuServicesColor',
	fontWeight = 'regular',
	children,
	reverse = false,
	arrowColor = null,
	isHovered,
	...props
}) {
	return (
		<Button color={color} fontWeight={fontWeight} reverse={reverse} {...props} arrowColor={arrowColor} isHovered={isHovered}>
			{children}
			<ArrowLongIcon />
		</Button>
	);
}

export default GhostArrowButton;
