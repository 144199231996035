import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ArrowLongIcon from '../inlineSvg/ArrowLongIcon';

const StyledArrowButtonContainer = styled.div`
	display: inline-block;
	pointer-events: auto;
`;

const StyledArrowButtonWrapper = styled.div`
	border-radius: 6px;
	border-style: solid;
	border-width: 1.5px;
	display: flex;
	flex-direction: row;
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	height: 50px;

	.arrow-icon {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		transform: translateX(0);
		transition: transform 0.2s ease-in-out;
	}

	&:hover {
		.arrow-icon {
			transform: translateX(7px);
			transition: transform 0.15s ease-in-out 0.05s;
		}
	}

	justify-content: flex-start;

	&.primary-white {
		background-color: ${({ theme }) => theme.colors.white};
		border-color: ${({ theme }) => theme.colors.white};
		color: ${({ theme }) => theme.colors.navy};

		svg path {
			fill: ${({ theme }) => theme.colors.navy};
		}

		.arrow-square {
			border-color: ${({ theme }) => theme.colors.grey};
		}

		p.small {
			color: ${({ theme }) => theme.colors.navy};
		}

		&:hover {
			background-color: transparent;
			border-color: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.white};
			transition: all 0.3s ease-in-out;

			svg path {
				fill: ${({ theme }) => theme.colors.white};
			}

			.arrow-square {
				border-color: ${({ theme }) => theme.colors.white};
			}

			p.small {
				color: ${({ theme }) => theme.colors.whiteAlpha60};
			}
		}
	}

	&.primary-navy {
		background-color: ${({ theme }) => theme.colors.navy};
		border-color: ${({ theme }) => theme.colors.navy};
		color: ${({ theme }) => theme.colors.white};

		svg path {
			fill: ${({ theme }) => theme.colors.white};
		}

		.arrow-square {
			border-color: ${({ theme }) => theme.colors.white};
		}

		p.small {
			color: ${({ theme }) => theme.colors.white};
		}

		&:hover {
			background-color: transparent;
			border-color: ${({ theme }) => theme.colors.navy};
			color: ${({ theme }) => theme.colors.navy};

			svg path {
				fill: ${({ theme }) => theme.colors.navy};
			}

			.arrow-square {
				border-color: ${({ theme }) => theme.colors.navy};
			}

			p.small {
				color: ${({ theme }) => theme.colors.navyAlpha60};
			}
		}
	}

	&.primary-green {
		background-color: ${({ theme }) => theme.colors.green};
		border-color: ${({ theme }) => theme.colors.green};
		color: ${({ theme }) => theme.colors.navy};

		svg path {
			fill: ${({ theme }) => theme.colors.navy};
		}

		.arrow-square {
			border-color: ${({ theme }) => theme.colors.greenLight};
		}

		p.small {
			color: ${({ theme }) => theme.colors.navy};
		}
	}

	&.secondary-white {
		background-color: transparent;
		border-color: ${({ theme }) => theme.colors.white};
		color: ${({ theme }) => theme.colors.white};

		svg path {
			fill: ${({ theme }) => theme.colors.white};
		}

		.arrow-square {
			border-color: ${({ theme }) => theme.colors.white};
		}

		p.small {
			color: ${({ theme }) => theme.colors.whiteAlpha60};
		}

		&:hover {
			background-color: ${({ theme }) => theme.colors.white};
			border-color: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.navy};

			svg path {
				fill: ${({ theme }) => theme.colors.navy};
			}

			.arrow-square {
				border-color: ${({ theme }) => theme.colors.grey};
			}

			p.small {
				color: ${({ theme }) => theme.colors.navy};
			}
		}
	}

	&.secondary-navy {
		background-color: transparent;
		border-color: ${({ theme }) => theme.colors.navy};
		color: ${({ theme }) => theme.colors.navy};

		svg path {
			fill: ${({ theme }) => theme.colors.navy};
		}

		.arrow-square {
			border-color: ${({ theme }) => theme.colors.navy};
		}

		p.small {
			color: ${({ theme }) => theme.colors.navyAlpha60};
		}

		&:hover {
			background-color: ${({ theme }) => theme.colors.navy};
			border-color: ${({ theme }) => theme.colors.navy};
			color: ${({ theme }) => theme.colors.white};

			svg path {
				fill: ${({ theme }) => theme.colors.white};
			}

			.arrow-square {
				border-color: ${({ theme }) => theme.colors.white};
			}

			p.small {
				color: ${({ theme }) => theme.colors.white};
			}
		}
	}

	&.special {
		background-color: transparent;
		border-color: ${({ theme }) => theme.colors.navy};
		color: ${({ theme }) => theme.colors.navy};

		svg path {
			fill: ${({ theme }) => theme.colors.navy};
		}

		.arrow-square {
			border-color: ${({ theme }) => theme.colors.navy};
		}

		p.small {
			color: ${({ theme }) => theme.colors.navyAlpha60};
		}

		&:hover {
			background-color: ${({ theme }) => theme.colors.green};
			border-color: ${({ theme }) => theme.colors.green};
			color: ${({ theme }) => theme.colors.navy};

			svg path {
				fill: ${({ theme }) => theme.colors.navy};
			}

			.arrow-square {
				border-color: ${({ theme }) => theme.colors.white};
			}

			p.small {
				color: ${({ theme }) => theme.colors.navy};
			}
		}
	}

	transition: all 0.3s ease-in-out;
	width: fit-content;
	@media (min-width: 768px) {
		height: 67px;
	}
	@media (min-width: 1280px) {
		height: 50px;
	}
`;

const StyledButtonLeftPart = styled.div`
	display: flex;
	flex-direction: column;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	height: 100%;
	justify-content: center;
	padding: 12px 20px;
	@media (min-width: 768px) {
		padding: 13px 20px;
	}

	p.small {
		font-size: 9px;
		line-height: 11px;
		text-transform: uppercase;
		@media (min-width: 1280px) {
			font-size: 9px;
			line-height: 11px;
		}
	}

	p.text {
		font-size: 16px;
		line-height: 17px;
		@media (min-width: 768px) {
			font-size: 14px;
			line-height: 17px;
		}
		@media (min-width: 1280px) {
			font-size: 16px;
			line-height: 17px;
		}
	}
`;

const StyledButtonRightPart = styled.div`
	align-items: center;
	border-style: solid;
	border-width: 0 0 0 1.5px;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	width: 50px;

	svg {
		height: 15px;
		width: 15px;
	}

	@media (min-width: 768px) {
		width: 67px;
		svg {
			width: 15px;
			height: 15px;
		}
	}
	@media (min-width: 1280px) {
		width: 50px;
		svg {
			width: 15px;
			height: 15px;
		}
	}
`;

function ArrowButton({ variant = 'secondary-white', smallText, text, link, href, onClick }) {
	return (
		<StyledArrowButtonContainer className="button-container">
			{link && (
				<StyledArrowButtonWrapper as={Link} to={link} className={variant}>
					<StyledButtonLeftPart>
						<p className="small">{smallText}</p>
						<p className="text">{text}</p>
					</StyledButtonLeftPart>
					<StyledButtonRightPart className="arrow-square">
						<div className="arrow-icon">
							<ArrowLongIcon />
						</div>
					</StyledButtonRightPart>
				</StyledArrowButtonWrapper>
			)}
			{!link && !href && (
				<StyledArrowButtonWrapper as="div" className={variant} onClick={onClick}>
					<StyledButtonLeftPart>
						<p className="small">{smallText}</p>
						<p className="text">{text}</p>
					</StyledButtonLeftPart>
					<StyledButtonRightPart className="arrow-square">
						<div className="arrow-icon">
							<ArrowLongIcon />
						</div>
					</StyledButtonRightPart>
				</StyledArrowButtonWrapper>
			)}
			{href && (
				<StyledArrowButtonWrapper as="a" href={href} target="_blank" className={variant}>
					<StyledButtonLeftPart>
						<p className="small">{smallText}</p>
						<p className="text">{text}</p>
					</StyledButtonLeftPart>
					<StyledButtonRightPart className="arrow-square">
						<div className="arrow-icon">
							<ArrowLongIcon />
						</div>
					</StyledButtonRightPart>
				</StyledArrowButtonWrapper>
			)}
		</StyledArrowButtonContainer>
	);
}

export default ArrowButton;
