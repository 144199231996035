import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import FooterText from '../../typography/FooterText';
import { getServicePageSlug, getSlug } from '../../../utils/getSlug';

const StyledBlockWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	//margin-bottom: 30px;
	margin-bottom: 30px;
	// margin-bottom: ${({ isSitemapVisible }) => (!isSitemapVisible ? '0' : '30px')};
	//transition: margin-bottom 0.3s ease-out;
	@media (min-width: 1280px) {
		margin-bottom: 0;
	}
	p.title {
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		font-size: 13px;
		line-height: 20px;
		margin-bottom: 14px;
		cursor: pointer;
		@media (min-width: 1024px) {
			font-size: 17px;
			line-height: 20px;
		}
		@media (min-width: 1280px) {
			font-size: 13px;
			line-height: 20px;
		}
	}
	&.apius {
		width: 100%;
		@media (min-width: 1440px) {
			width: 20%;
		}
		p.title {
			background: linear-gradient(90deg, #0cedc7 -9.21%, #9747ff 13%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
	&.cybersecurity,
	&.network,
	&.cloud,
	&.services {
		width: 100%;
		@media (min-width: 768px) {
			width: 40%;
		}
		@media (min-width: 1440px) {
			width: 25%;
		}
	}
	&.cybersecurity {
		color: ${({ theme }) => theme.colors.green};
	}
	&.network {
		color: ${({ theme }) => theme.colors.footerNetworkViolet};
	}
	&.cloud {
		color: ${({ theme }) => theme.colors.footerCloudBlue};
	}
	&.services {
		color: ${({ theme }) => theme.colors.footerServicesYellow};
	}
	&.policy {
		color: ${({ theme }) => theme.colors.footerGray};
		@media (min-width: 768px) {
			margin-bottom: 0;
		}
	}
`;

const StyledLinksWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-height: 0;
	transform-origin: top;
	transition: max-height 0.5s ease-out;
	&.apius {
		display: flex;
		max-height: 0;
		@media (min-width: 768px) {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			width: 100%;
			a {
				margin-right: 30px;
			}
		}
		@media (min-width: 1440px) {
			flex-direction: column;
		}
	}
	&.policy {
		max-height: 1000px;
		opacity: 1;
	}
`;

const StyledSingleLink = styled(Link)`
	color: ${({ theme }) => theme.colors.white};
	display: ${({ $isSitemapVisible }) => ($isSitemapVisible ? 'block' : 'none')};
	font-size: 13px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 20px;
	opacity: 0;
	transform: translateX(20px);
	@media (min-width: 1280px) {
		display: block;
	}
	&.policy {
		opacity: 1;
		transform: translateX(0);
	}
`;

function FooterTitleAndLinksBlock({ data, activeSite, isSitemapVisible, type, toggleSitemapVisibility }) {
	const [animation, setAnimation] = useState(null);
	const [isReversed, setIsReversed] = useState(false);
	const title = data?.title || '';
	const pages = data?.[type === 'apius' || type === 'policy' ? 'choosePages' : 'chooseService'] || [];
	const linksRef = useRef();

	useEffect(() => {
		if (type !== 'policy') {
			if (!animation) {
				const tl = gsap.timeline({ paused: true });
				tl.to(linksRef.current, {
					maxHeight: 1000,
					duration: 0.2,
					ease: 'expo.out',
				}).to(linksRef.current.children, {
					stagger: 0.1,
					opacity: 1,
					x: 0,
					ease: 'expo.inOut',
				});
				setAnimation(tl);
			} else {
				if (!isReversed) {
					animation.play();
				} else {
					animation.reverse();
				}
				setIsReversed(!isReversed);
			}
		}
	}, [isSitemapVisible]);

	return (
		<StyledBlockWrapper className={type} isSitemapVisible={isSitemapVisible}>
			<p onClick={toggleSitemapVisibility} className="title">
				{title}
			</p>
			{/* <StyledLinksWrapper className={isSitemapVisible ? `visible ${type}` : `${type}`}> */}
			<StyledLinksWrapper ref={linksRef} className={type}>
				{pages.map((item) => {
					const slug = item?.slug || '';
					const singlePageName = item?.[type === 'apius' || type === 'policy' ? 'singlePageName' : 'name'] || '';
					const finalSlug =
						type === 'apius' || type === 'policy'
							? `${getSlug(slug, activeSite)}`
							: `${getServicePageSlug(slug, type, activeSite)}`;
					return (
						<StyledSingleLink $isSitemapVisible={isSitemapVisible} className={type} key={singlePageName} to={finalSlug}>
							<FooterText className={type}>{singlePageName}</FooterText>
						</StyledSingleLink>
					);
				})}
			</StyledLinksWrapper>
		</StyledBlockWrapper>
	);
}

export default FooterTitleAndLinksBlock;
