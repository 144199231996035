import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

function useInterval(callback, delay) {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

const StyledDotsGridAnimation = styled.div`
	display: grid;
	grid-gap: ${({ dotsGap }) => `${dotsGap}px`};
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: 1fr 1fr 1fr;
	margin: 0 18px 0 15px;
	transform: translateY(-1px);

	div {
		transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;

		&:nth-child(2),
		&:nth-child(6),
		&:nth-child(8),
		&:nth-child(10),
		&:nth-child(11),
		&:nth-child(12),
		&:nth-child(16),
		&:nth-child(18),
		&:nth-child(20),
		&:nth-child(21) {
			background-color: ${({ color }) => color};
		}
	}

	&:hover,
	&.hover {
		div {
			&:nth-child(2) {
				transform: translateX(${({ dotWidth, dotsGap }) => 4 * dotWidth + 4.5 * dotsGap}px);
			}

			&:nth-child(6) {
				transform: translateX(${({ dotWidth, dotsGap }) => -(3 * dotWidth + 3.5 * dotsGap)}px);
			}

			&:nth-child(8) {
				transform: translateX(${({ dotWidth, dotsGap }) => 2 * dotWidth + 2 * dotsGap}px);
			}

			&:nth-child(10) {
				transform: translateX(${({ dotWidth, dotsGap }) => dotWidth + dotsGap}px);
			}

			&:nth-child(11) {
				transform: translateX(${({ dotWidth, dotsGap }) => -(2 * dotWidth + 2 * dotsGap)}px);
			}

			&:nth-child(12) {
				transform: translateX(${({ dotWidth, dotsGap }) => dotWidth + dotsGap}px);
			}

			&:nth-child(16) {
				transform: translateX(${({ dotWidth, dotsGap }) => 2 * dotWidth + 2 * dotsGap}px);
			}

			&:nth-child(18) {
				transform: translateX(${({ dotWidth, dotsGap }) => dotWidth + 1.5 * dotsGap}px);
			}

			&:nth-child(20) {
				transform: translateX(${({ dotWidth, dotsGap }) => 0.5 * dotWidth + dotsGap}px);
			}

			&:nth-child(21) {
				transform: translateX(${({ dotWidth, dotsGap }) => -(4 * dotWidth + 4.5 * dotsGap)}px);
			}
		}
	}
`;

const StyledSingleDot = styled.div`
	background-color: transparent;
	border-radius: 50%;
	height: ${({ dotWidth }) => `${dotWidth}px`};
	width: ${({ dotWidth }) => `${dotWidth}px`};
`;

function ShufflingDots({ rows = 1, columns = 1, color = '#fff', isHovered, intervalAnimation }) {
	const numberOfItems = rows * columns;
	const tempArray = [...Array(numberOfItems)];
	const dotWidth = 2;
	const dotsGap = 3;
	const [switchAnimation, setSwitchAnimation] = useState(false);
	if (intervalAnimation) {
		useInterval(() => {
			if (!isHovered) {
				setSwitchAnimation(!switchAnimation);
			}
		}, 600);
	}
	const switchAnimationClassName = intervalAnimation ? switchAnimation : isHovered;
	return (
		<StyledDotsGridAnimation
			className={switchAnimationClassName ? 'hover shuffling-dots' : 'shuffling-dots'}
			dotWidth={dotWidth}
			dotsGap={dotsGap}
			color={color}
		>
			{tempArray.map((item, key) => (
				<StyledSingleDot dotWidth={dotWidth} dotsGap={dotsGap} key={key} />
			))}
		</StyledDotsGridAnimation>
	);
}

export default ShufflingDots;
