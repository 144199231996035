import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
	name: 'menu',
	initialState: {
		isOpen: false,
		type: 'narrow',
		previousType: 'narrow',
	},
	reducers: {
		setIsMenuOpen: (state, action) => {
			state.isOpen = action.payload;
		},
		setMenuType: (state, action) => {
			state.type = action.payload;
		},
		setPreviousMenuType: (state, action) => {
			state.previousType = action.payload;
		},
	},
});

export const { setIsMenuOpen, setMenuType, setPreviousMenuType, setIsSticky } = menuSlice.actions;

export default menuSlice.reducer;
