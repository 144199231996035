import React, { useEffect, useRef } from 'react';
import ArrowUp from '../../assets/images/up_apius.png';
import styled from 'styled-components';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { StaticImage } from 'gatsby-plugin-image';

const StyledScrollTopButtonWrapper = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 7px;
	right: 75px;
	opacity: 0;
	pointer-events: none;
	@media (min-width: 768px) {
		bottom: 40px;
	}
	@media (min-width: 1280px) {
		bottom: 23px;
	}
	cursor: pointer;
	z-index: 24;

	&.show {
		pointer-events: auto;
		opacity: 1;
	}

	transition: opacity 0.3s ease-in-out;
`;

const ScrollTopButton = () => {
	const { scroll } = useLocomotiveScroll();
	const WrapperRef = useRef(null);
	useEffect(() => {
		if (scroll) {
			scroll.on('scroll', handleMoveTopButton);
		}
	}, [scroll]);
	const handleMoveTopButton = (instance) => {
		const top = instance.scroll.y;
		const searchButton = document.getElementById('search-desktop-button');
		if (!WrapperRef.current) return;
		if (top <= 200) {
			WrapperRef.current.classList.remove('show');
			if (searchButton) {
				searchButton.classList.remove('move-left');
			}
		} else {
			WrapperRef.current.classList.add('show');
			if (searchButton) {
				searchButton.classList.add('move-left');
			}
		}
	};

	return (
		<StyledScrollTopButtonWrapper id="move-top-button" ref={WrapperRef} onClick={() => scroll.scrollTo('top')}>
			<StaticImage alt="arrow-top" src="../../assets/images/up_apius.png" height={38} quality={100} placeholder="blurred" />
		</StyledScrollTopButtonWrapper>
	);
};

export default ScrollTopButton;
