import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import LoupeIcon from '../inlineSvg/LoupeIcon';
import useMediaQuery from '../../hooks/useMediaQuery';

const StyledSearchForm = styled.form`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 13px;
	line-height: 15px;
	font-family: ${({ theme }) => theme.fontFamily.robotoFont};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	color: ${({ theme }) => theme.colors.green};
	padding: 10px 18px 10px 24px;
	border: 2px solid ${({ theme }) => theme.colors.green};
	border-radius: 6px;
	position: relative;
	margin-top: 10px;
	svg {
		width: 11px;
		height: 11px;
	}
`;

const StyledSearchInput = styled.input`
	background-color: transparent;
	border: none;
	color: ${({ theme }) => theme.colors.white};
	outline: none;
	font-family: ${({ theme }) => theme.fontFamily.robotoFont};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	font-size: 16px;
	line-height: 15px;
	caret-color: ${({ theme }) => theme.colors.green};
	width: 100%;
	@media (min-width: 768px) {
		font-size: 13px;
	}
	::placeholder {
		color: ${({ theme }) => theme.colors.green};
	}
`;

const StyledCloseButton = styled.div`
	position: absolute;
	right: -20px;
	top: 50%;
	transform: translateY(-50%);
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	font-weight: ${({ theme }) => theme.fontWeight.light};
	font-size: 19px;
	line-height: 39px;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	@media (min-width: 768px) {
		right: -30px;
	}
`;

const SearchBox = ({ className, onFocus, onChange, handleCloseSearch, placeholder, hasFocus, isDesktop }) => {
	const { query, refine } = useSearchBox();
	const inputRef = useRef();

	useEffect(() => {
		if (isDesktop) {
			inputRef.current.focus();
		}
	}, [hasFocus]);
	return (
		<StyledSearchForm className={className}>
			<StyledSearchInput
				ref={inputRef}
				className="SearchInput"
				type="text"
				placeholder={placeholder}
				aria-label={placeholder}
				onChange={(e) => {
					refine(e.target.value);
					onChange(e.target.value);
				}}
				value={query}
				onFocus={onFocus}
			/>
			<LoupeIcon />
			<StyledCloseButton onClick={handleCloseSearch}>x</StyledCloseButton>
		</StyledSearchForm>
	);
};

export default SearchBox;
