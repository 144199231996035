import { configureStore } from '@reduxjs/toolkit';
import language from './languageSlice';
import menu from './menuSlice';
import apiusSpace from './apiusSpaceSlice';
import colorScheme from './colorSchemeSlice';
import DNA from './DNASlice';
import searchBar from './searchBarSlice';

export default configureStore({
	reducer: {
		language,
		menu,
		apiusSpace,
		colorScheme,
		DNA,
		searchBar,
	},
});
