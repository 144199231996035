import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import CategoryTab from '../categoryTab/CategoryTab';
import useMediaQuery from '../../hooks/useMediaQuery';
import { getSlug } from '../../utils/getSlug';
import { setIsApiusSpaceOpen } from '../../state/apiusSpaceSlice';

const StyledArticleCardWrapper = styled.div.withConfig({
	shouldForwardProp: (prop) => !['isLarge', 'slider'].includes(prop),
})`
	//width: 290px;
	//width: 346px;
	border: 2px solid rgba(42, 193, 215, 1);
	border-radius: 20px;
	cursor: ${({ slider }) => (slider ? 'none' : 'pointer')};
	//cursor: none;
	margin-bottom: 26px;
	min-height: 410px;
	overflow: hidden;
	position: relative;
	width: 100%;
	min-width: ${({ slider }) => (slider ? '290px' : 'unset')};
	display: flex;
	flex-direction: column;
	@media (min-width: 600px) {
		width: calc((100% - 27px) / 2);
	}
	@media (min-width: 768px) {
		width: calc((100% - 27px) / 2);
	}
	@media (min-width: 1024px) {
		width: 430px;
		margin-bottom: 36px;
	}
	@media (min-width: 1280px) {
		width: ${({ isLarge }) => (isLarge ? 590 : 280)}px;
		height: 410px;
		display: ${({ isLarge }) => (isLarge ? 'flex' : 'block')};
		flex-direction: ${({ isLarge }) => (isLarge ? 'row' : 'column')};
	}
	@media (min-width: 1920px) {
		width: ${({ isLarge }) => (isLarge ? 650 : 310)}px;
		margin-bottom: 32px;
	}
	&:hover {
		transition: transform 0.25s ease-in-out;
		.card-image {
			transform: scale(0.95);
			transition: transform 0.25s ease-in-out;
		}
	}
	&:hover {
		background-color: rgba(255, 255, 255, 0.05);
	}
`;

const StyledImageWrapper = styled.div`
	position: relative;
	transform: scale(1);
	transition: transform 0.25s ease-in-out;
`;

const StyledImageContainer = styled.div`
	border-radius: 18px;
	isolation: isolate;
	overflow: hidden;
	width: 100%;
	.gatsby-image-wrapper {
		border-radius: 20px;
		height: auto;
	}
	&.large {
		@media (min-width: 1280px) {
			//width: auto;
			width: 288px;
			height: 100%;
			.gatsby-image-wrapper {
				width: 288px;
				height: 100%;
				border-radius: 20px;
			}
		}
		@media (min-width: 1920px) {
			width: 322px;
			.gatsby-image-wrapper {
				width: 322px;
			}
		}
	}
`;

const StyledCardContentWrapper = styled.div`
	//min-height: 220px;
	padding: 20px 20px 60px;
	@media (min-width: 1024px) {
		padding: 30px 30px 70px;
	}
	@media (min-width: 1280px) {
		padding: 16px 26px 26px 26px;
	}
	&.large {
		@media (min-width: 1280px) {
			padding: 29px 42px;
			position: relative;
		}
	}
`;

const StyledCategoryTabsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	grid-gap: 10px;
	justify-content: flex-start;
	margin-bottom: 26px;
	@media (min-width: 1280px) {
		margin-bottom: ${({ isLarge }) => (isLarge ? 50 : 36)}px;
	}
	p.show-more {
		color: #fff;
		font-size: 14px;
		line-height: 16px;
		margin: auto 0;
		opacity: 0.21;
		@media (min-width: 1024px) {
			font-size: 20px;
			line-height: 23px;
		}
		@media (min-width: 1280px) {
			font-size: 14px;
			line-height: 16px;
		}
	}
`;

const StyledArticleTitle = styled.p`
	color: #ffffff;
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	font-size: 24px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 29px;
	@media (min-width: 1280px) {
		font-size: 20px;
		line-height: 24px;
	}
`;

const StyledReadingTime = styled.div`
	bottom: 26px;
	color: ${({ theme }) => theme.colors.whiteAlpha52};
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	left: 26px;
	line-height: 16px;
	position: absolute;
	&.large {
		@media (min-width: 1280px) {
			left: 47px;
			bottom: 27px;
		}
		@media (min-width: 1920px) {
			left: 42px;
		}
	}
`;

function SingleArticleCard({ className, data, activeSite, readingTime, largeCard, slider, wasContentScrolled }) {
	const title = data?.articleName || '';
	const description = data?.articleShortDescription || '';
	const createdAt = data?.articleCreationDate || '';
	const slug = data?.slug || '';
	const image = data?.articlePhotoForTile?.gatsbyImageData || '';
	const imageVertical = data?.articlePhotoForTileVertical?.gatsbyImageData || '';
	const gatsbyImage = getImage(image);
	const imageAlt = data?.articlePhotoForTile?.alt || '';
	const articleCategories = data?.chooseArticleCategory || [];
	const articleTags = data?.chooseArticleTag || [];
	const readingTimeNumber = data?.readingTime || 1;
	const combinedTagsAndCategories = useMemo(() => {
		const initArray = [];
		articleCategories.forEach((item) => {
			initArray.push({
				name: item?.articleCategoryName || '',
				className: item?.articleCategoryName?.replace(/\s+/g, '-') || '',
			});
		});
		articleTags.forEach((item) => {
			initArray.push({
				name: item?.articleTagName || '',
				className: item?.articleTagName?.replace(/\s+/g, '-') || '',
			});
		});
		return initArray;
	}, [activeSite]);
	const combinedClassNameArray = combinedTagsAndCategories.map((item) => item.name.replace(/\s+/g, '-'));
	const combinedClassName = combinedClassNameArray.join(' ');
	const isDesktop = useMediaQuery('(min-width: 1280px)');
	const cardImageSource = isDesktop && largeCard ? imageVertical : image;
	const [showAllTags, setShowAllTags] = useState(false);
	const tagsAlwaysVisible = combinedTagsAndCategories.length <= 2 ? combinedTagsAndCategories : combinedTagsAndCategories.slice(0, 2);
	const restOfTags = combinedTagsAndCategories.length <= 2 ? [] : combinedTagsAndCategories.slice(2, combinedTagsAndCategories.length);
	const numberOfHiddenTags = restOfTags?.length || 0;
	const moreText = activeSite === 'pl' ? 'więcej' : 'more';
	const linkToNavigate = `${getSlug(slug, activeSite)}`;
	const dispatch = useDispatch();
	const handleCardClick = () => {
		const currentPath = window?.location?.pathname || '';
		if (currentPath.includes(linkToNavigate)) {
			dispatch(setIsApiusSpaceOpen(false));
		}
		if (!wasContentScrolled) {
			navigate(linkToNavigate);
		}
	};
	return (
		<StyledArticleCardWrapper
			as={slider ? 'div' : Link}
			onClick={() => handleCardClick()}
			to={`${getSlug(slug, activeSite)}`}
			isLarge={largeCard}
			className={`box ${combinedClassName}`}
			slider={slider}
		>
			<StyledImageWrapper className="card-image">
				<StyledImageContainer className={largeCard ? 'large' : ''}>
					<GatsbyImage alt={imageAlt} image={cardImageSource} />
				</StyledImageContainer>
			</StyledImageWrapper>
			<StyledCardContentWrapper className={largeCard ? 'large' : ''}>
				<div>
					<StyledCategoryTabsWrapper className={largeCard ? 'large' : ''}>
						{tagsAlwaysVisible.map((item, key) => {
							const name = item?.name || '';
							const nameDashed = name.replace(/\s+/g, '-');
							return (
								<CategoryTab key={name} className={nameDashed} categoryName={name} isGradientBackground slider={slider} />
							);
						})}
						{restOfTags.length > 0 && !showAllTags && (
							<p className="show-more" onMouseOver={() => setShowAllTags(!showAllTags)}>
								{showAllTags ? '' : `+ ${numberOfHiddenTags} ${moreText}`}
							</p>
						)}
						{showAllTags &&
							restOfTags.length > 0 &&
							restOfTags.map((item, key) => {
								const name = item?.name || '';
								const nameDashed = name.replace(/\s+/g, '-');
								return (
									<CategoryTab
										key={name}
										className={nameDashed}
										categoryName={name}
										isGradientBackground
										slider={slider}
									/>
								);
							})}
					</StyledCategoryTabsWrapper>
					<StyledArticleTitle>{title}</StyledArticleTitle>
				</div>
				<StyledReadingTime className={largeCard ? 'large' : ''}>
					{activeSite === 'pl' ? `${`${readingTime} ${readingTimeNumber}`} min` : `${readingTimeNumber}min ${readingTime}`}
				</StyledReadingTime>
			</StyledCardContentWrapper>
		</StyledArticleCardWrapper>
	);
}

export default SingleArticleCard;
