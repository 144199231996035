import { useEffect, useState } from 'react';
import useMediaQuery from './useMediaQuery';
import themeSettings from '../styles/themeSettings';

export default function useIsDesktopVersion() {
	const [isDesktopVersion, setIsDesktopVersion] = useState(true);
	const isTabletLandscape = useMediaQuery(`(min-width:${themeSettings.breakpoints.tablet}px) and (orientation: landscape)`);
	const isDesktop = useMediaQuery(`(min-width:${themeSettings.breakpoints.desktop}px)`);
	useEffect(() => {
		if (isTabletLandscape || isDesktop) setIsDesktopVersion(true);
		else setIsDesktopVersion(false);
	}, [isTabletLandscape, isDesktop]);
	return isDesktopVersion;
}
