import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getSlug } from '../../utils/getSlug';
import { setIsMenuOpen } from '../../state/menuSlice';
import { setIsSearchBarOpened } from '../../state/searchBarSlice';

const searchIndicesPL = [
	{ name: `service_pl`, title: `Usługi` },
	{ name: `single_pl`, title: `Pozostałe` },
];

const searchIndicesEN = [
	{ name: `service_en`, title: `Services` },
	{ name: `single_en`, title: `Other Pages` },
];

const Wrapper = styled.div`
	color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	width: 100%;

	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
		padding-top: 89px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		padding-top: 89px;
	}
`;
const Top = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		row-gap: 25px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		row-gap: 35px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
		row-gap: 15px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		row-gap: 15px;
	}
`;
const Item = styled.span`
	color: ${({ theme, green }) => (green ? theme.colors.green : 'white')};
	font-size: ${({ small }) => (small ? 12 : 32)}px;
	font-weight: ${({ theme, bold }) => (bold ? theme.fontWeight.black : theme.fontWeight.regular)};
	position: relative;
	width: fit-content;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		font-size: ${({ small }) => (small ? 14 : 42)}px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		font-size: ${({ small }) => (small ? 16 : 45)}px;
	}

	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
		font-size: ${({ small }) => (small ? 12 : 32)}px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		font-size: ${({ small }) => (small ? 12 : 32)}px;
	}

	&.search {
		display: block;
		@media (min-width: 1280px) {
			display: none;
		}
	}

	${({ grow }) => grow && 'flex-grow: 2;'}
	a {
		transition: font-weight 0.25s ease-in-out;
	}

	a:hover {
		font-weight: ${({ theme }) => theme.fontWeight.black};
	}
`;
export const Bottom = styled.div`
	column-gap: 25px;
	display: flex;
	justify-content: flex-start;
	margin-top: 24px;
	position: relative;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		column-gap: 25px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
		column-gap: 15px;
		margin-top: unset;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		column-gap: 15px;
		margin-top: unset;
	}
`;

function MenuNarrow({ data = [], socials, locale, otherLocale, otherLangSlug }) {
	const dispatch = useDispatch();
	const isSearchBarVisible = useSelector((state) => state.searchBar.isOpen);
	return (
		<Wrapper>
			<Top>
				{data.map((item) => {
					const { title, id } = item;
					const { slug } = item.link;
					return (
						<Item key={id} onClick={() => dispatch(setIsMenuOpen(false))}>
							<Link to={getSlug(slug, locale)}>{title || ''}</Link>
						</Item>
					);
				})}
			</Top>
			<Bottom>
				<Item className="search" small bold grow onClick={() => dispatch(setIsSearchBarOpened(!isSearchBarVisible))}>
					{locale === 'pl' ? 'szukaj' : 'search'}
				</Item>
				{/* <Item small bold grow> */}
				{/*	<Link className="underlineHover" to={getSlug(otherLangSlug, otherLocale)}> */}
				{/*		{otherLocale.toLowerCase()} */}
				{/*	</Link> */}
				{/* </Item> */}
				{socials.map((social) => {
					const { title, slug, id } = social;
					return (
						<Item small bold key={id}>
							<a href={slug || '/'} target="_blank" rel="noreferrer" className="underlineHover">
								{title || ''}
							</a>
						</Item>
					);
				})}
			</Bottom>
		</Wrapper>
	);
}

export default MenuNarrow;
