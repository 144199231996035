import { createSlice } from '@reduxjs/toolkit';

export const apiusSpaceSlice = createSlice({
	name: 'apiusSpace',
	initialState: {
		isOpen: false,
	},
	reducers: {
		setIsApiusSpaceOpen: (state, action) => {
			state.isOpen = action.payload;
		},
	},
});

export const { setIsApiusSpaceOpen } = apiusSpaceSlice.actions;

export default apiusSpaceSlice.reducer;
