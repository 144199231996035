import { createSlice } from '@reduxjs/toolkit';

export const colorScheme = createSlice({
	name: 'theme',
	initialState: 'dark',
	reducers: {
		setColorScheme: (state, action) => action.payload,
	},
});

export const { setColorScheme } = colorScheme.actions;

export default colorScheme.reducer;
