import { createSlice } from '@reduxjs/toolkit';

export const searchBarSlice = createSlice({
	name: 'searchBar',
	initialState: {
		isOpen: false,
	},
	reducers: {
		setIsSearchBarOpened: (state, action) => {
			state.isOpen = action.payload;
		},
	},
});

export const { setIsSearchBarOpened } = searchBarSlice.actions;

export default searchBarSlice.reducer;
