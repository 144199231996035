import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import themeSettings from '../styles/themeSettings';
import GlobalStyle from '../styles/GlobalStyles';
import LocomotiveStyles from '../styles/LocomotiveStyles';
import Menu from '../components/Menu/Menu';
import { setIsMenuOpen } from '../state/menuSlice';
import Footer from '../components/footer/Footer';
import { setDNAType } from '../state/DNASlice';
import useIsDesktopVersion from '../hooks/useIsDesktopVersion';
import ApiusSpaceBar from '../components/apiusSpaceBar/ApiusSpaceBar';
import SearchMain from '../components/search/SearchMain';
import SearchButton from '../components/searchButton/SearchButton';
import { setIsSearchBarOpened } from '../state/searchBarSlice';
import Cookies from '../components/cookies/Cookies';
import ScrollTopButton from '../components/scrollTopButton/ScrollTopButton';
import Overlay from '../components/common/Overlay';

const WebGL = lazy(() => import('../components/WebGL/WebGL'));

const searchIndicesPL = [
	{ name: `service_apius23_pl`, title: `Usługi` },
	{ name: `single_apius23_pl`, title: `Pozostałe` },
	{ name: `product_apius23_pl`, title: `Produkty` },
	{ name: `single_article_apius23_pl`, title: `Artykuły` },
];

const searchIndicesEN = [
	{ name: `service_apius23_en`, title: `Services` },
	{ name: `single_apius23_en`, title: `Other Pages` },
	{ name: `product_apius23_en`, title: `Products` },
	{ name: `single_article_apius23_en`, title: `Articles` },
];

const Main = styled.main`
	margin: 0 auto;
	max-width: 100vw;
	overflow-x: hidden;
	position: relative;
	z-index: ${({ theme }) => theme.layers.second};
`;

const ScrollTarget = styled.div`
	background: transparent;
	bottom: 0;
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1;
`;

const StyledDummyBar = styled.div`
	align-items: center;
	backdrop-filter: blur(20px);
	background: linear-gradient(
		90deg,
		#0feac0 2.99%,
		rgba(11, 17, 66, 0.6) 30.98%,
		rgba(151, 71, 255, 0.6) 70.46%,
		rgba(12, 237, 199, 0.6) 98.95%
	);
	cursor: pointer;
	display: flex;
	flex-direction: row;
	height: 35px;
	justify-content: flex-end;
	left: 0;
	padding: 0 13px;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 100;
	@media (min-width: 768px) {
		padding: 0 48px;
		height: 44px;
	}
	@media (min-width: 1024px) {
		height: 59px;
		padding: 0 65px;
	}
	@media (min-width: 1440px) {
		display: none;
	}
`;

const StyledGradientWrapper = styled.div`
	background: linear-gradient(-15.96deg, #0feac0 -11.36%, rgba(25, 22, 150, 0.7) 49.56%, rgba(25, 18, 175, 0.8) 69.32%, #1a2341 107.33%);
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	@media (min-width: 1440px) {
		background: linear-gradient(279.29deg, #0feac0 1.44%, rgba(25, 22, 150, 0.5) 33.22%, rgba(25, 18, 175, 0.9) 65.48%, #1a2341 93.89%);
	}
`;

const StyledSearchBlendWrapper = styled.div`
	align-items: flex-start;
	background-color: rgba(14, 13, 54, 0.88);
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-start;
	left: 0;
	position: fixed;
	transform: scaleY(0);
	transform-origin: bottom;
	transition: transform 0.3s ease-in-out;
	width: 100%;
	z-index: 1000;
	@media (min-width: 768px) {
		padding: 40px 50px;
	}
	@media (min-width: 1280px) {
		padding: 40px 70px;
		display: flex;
		position: fixed;
		top: 0;
		bottom: unset;
		justify-content: flex-end;
	}

	&.visible {
		transform: scaleY(1);
		transition: transform 0.3s ease-in-out;
	}
`;

const StyledSearchButtonWrapper = styled.div`
	bottom: 45px;
	display: none;
	opacity: 1;
	padding: 10px;
	position: fixed;
	right: 93px;
	transform: translateX(0);
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
	@media (min-width: 768px) {
		bottom: 45px;
		right: 45px;
	}
	@media (min-width: 1024px) {
		bottom: 44px;
		//right: 150px;
		right: 100px;
	}
	@media (min-width: 1280px) {
		display: block;
	}
	z-index: 21;

	&.full {
		bottom: unset;
		display: block;
		left: unset;
		padding: 0;
		position: relative;
		right: unset;
		width: 100%;
		@media (min-width: 768px) {
			padding: 10px;
		}
	}

	&.hidden {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	&.move-left {
		transform: translateX(-40px);
		transition: transform 0.3s ease-in-out;
	}
`;

function Layout({ children, location, pageContext }) {
	const [isMobileDevice, setDevice] = useState(true);
	const isDesktopVersion = useIsDesktopVersion();
	const MainRef = useRef(null);
	const activeSite = pageContext?.locale || '';
	const DNA3D = pageContext?.DNA3D || {};
	const globalsData = pageContext?.globals?.data?.globalItems?.nodes?.[0] || [];
	const footerData = pageContext?.globals?.data?.footer?.nodes?.[0] || [];
	const menuData = pageContext?.globals?.data?.menu?.nodes || [];
	const apiusSpaceBuildData = pageContext?.apiusSpace?.data || [];
	const allArticlesData = apiusSpaceBuildData?.allArticles?.nodes || [];
	const apiusSpaceData = apiusSpaceBuildData?.apiusSpace?.nodes?.[0] || [];
	const articleTags = apiusSpaceBuildData?.allArticleTags?.nodes || [];
	const articleCategories = apiusSpaceBuildData?.allArticleCategories?.nodes || [];
	// const apiusSpaceIsOpen = useSelector((state) => state.apiusSpace.isOpen);
	const DNAStep = useSelector((state) => state.DNA.step);
	const DNACategory = useSelector((state) => state.DNA.category);
	const DNAService = useSelector((state) => state.DNA.service);
	const DNAHide = useSelector((state) => state.DNA.hide);
	const DNAType = useSelector((state) => state.DNA.type);
	const dispatch = useDispatch();
	const currentPageSlug = pageContext?.slug || '';
	const isApiusSpacePage = currentPageSlug === 'przestrzen-apiusa' || currentPageSlug === 'apius-space';
	const isSingleNewsPage = currentPageSlug.includes('aktualnosc') || currentPageSlug.includes('news');
	const isHomePage = currentPageSlug === '';
	useEffect(() => {
		setDevice(isMobile);
		DNA3D?.enabled && dispatch(setDNAType(DNA3D?.type || 'home'));
	}, []);

	const isSearchBarVisible = useSelector((state) => state.searchBar.isOpen);
	const handleCloseSearch = () => {
		dispatch(setIsSearchBarOpened(false));
	};

	const searchButtonText = activeSite === 'pl' ? 'Wyszukaj' : 'Search';
	const isArrowTopHidden = DNACategory !== '' && isHomePage;
	return (
		<ThemeProvider theme={themeSettings}>
			<GlobalStyle />
			<LocomotiveStyles />
			<LocomotiveScrollProvider
				options={{
					smooth: true,
					lerp: 0.2,
				}}
				watch={[DNAType, DNAHide, DNAStep, DNACategory, DNAService, isDesktopVersion]}
				location={location.pathname}
				containerRef={MainRef}
				onLocationChange={(scroll) => {
					scroll.scrollTo(0, { duration: 0, disableLerp: true, callback: scroll.setScroll(0, 0) });
					dispatch(setIsMenuOpen(false));
					if (DNA3D.enabled) {
						dispatch(setDNAType(DNA3D.type || 'home'));
					}
				}}
			>
				<Cookies locale={activeSite} />
				{/* <FlipCover /> */}
				{!isApiusSpacePage && (
					<ApiusSpaceBar
						articles={allArticlesData}
						spaceData={apiusSpaceData}
						tags={articleTags}
						categories={articleCategories}
						activeSite={activeSite}
						globals={globalsData}
					/>
				)}
				{isApiusSpacePage && isMobileDevice && <StyledDummyBar />}
				{isApiusSpacePage && <StyledGradientWrapper />}
				{!isApiusSpacePage && !isSingleNewsPage && (
					<>
						<StyledSearchButtonWrapper
							id="search-desktop-button"
							className={isSearchBarVisible ? 'hidden' : ''}
							onClick={() => dispatch(setIsSearchBarOpened(!isSearchBarVisible))}
						>
							<SearchButton text={searchButtonText} />
						</StyledSearchButtonWrapper>
						<StyledSearchBlendWrapper className={isSearchBarVisible ? 'visible' : ''}>
							<StyledSearchButtonWrapper className="full">
								<SearchMain
									className={isSearchBarVisible ? 'visible' : ''}
									isVisible={isSearchBarVisible}
									activeSite={activeSite}
									indices={activeSite === 'pl' ? searchIndicesPL : searchIndicesEN}
									handleCloseSearch={handleCloseSearch}
								/>
							</StyledSearchButtonWrapper>
						</StyledSearchBlendWrapper>
					</>
				)}
				{!isArrowTopHidden && <ScrollTopButton />}
				<Menu data={menuData} globals={globalsData} pageContext={pageContext} location={location} />
				<Main data-scroll-container ref={MainRef} id="scroll-container">
					{DNA3D.enabled && (
						<Suspense fallback={<Overlay />}>
							<WebGL data={footerData} context={pageContext} />
						</Suspense>
					)}
					{children}
					<ScrollTarget id="scroll-target" />
					<Footer data={footerData} globals={globalsData} activeSite={activeSite} />
				</Main>
			</LocomotiveScrollProvider>
		</ThemeProvider>
	);
}

export default Layout;
