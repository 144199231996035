import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import ShufflingDots from '../shufflingDots/ShufflingDots';
import Logo from '../Logo/Logo';
import useMediaQuery from '../../hooks/useMediaQuery';
import { setIsMenuOpen, setMenuType, setPreviousMenuType } from '../../state/menuSlice';
import themeSettings from '../../styles/themeSettings';
import { setColorScheme } from '../../state/colorSchemeSlice';
import ExtendedMenu from './ExtendedMenu';
import useIsDesktopVersion from '../../hooks/useIsDesktopVersion';
import useWindowSize from '../../hooks/useWindowSize';

const Wrapper = styled.div`
	display: flex;
	pointer-events: none;
	position: fixed;
	top: 0;
	width: 100%;

	&:before {
		backdrop-filter: ${({ isSticky, apiusSpace }) => `blur(${apiusSpace ? '0px' : isSticky ? '20px' : '0px'})`};
		background: ${({ isSticky, apiusSpace }) => `${apiusSpace ? '0px' : isSticky ? `rgba(0, 0, 0, 0.16)` : 'transparent'}`};
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		transition: backdrop-filter 0.3s ease-in-out, background 0.3s ease-in-out;
		width: 100%;
		@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
			(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
			display: none;
		}
	}

	z-index: ${({ theme }) => theme.layers.third};
`;

const Nav = styled.nav`
	display: flex;
	justify-content: space-between;
	padding: 35px 13px 0;
	width: 100%;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		padding: 44px 38px 0;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		padding: 59px 65px 0;
		@media (orientation: landscape) {
			padding: 23px 57.5px 0;
			transform: unset;
		}
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		padding: 23px 57.5px 0;
		transform: unset;
	}
`;

const ButtonsWrapper = styled.div`
	align-items: center;
	column-gap: 20px;
	display: grid;
	grid-template-columns: auto auto;
	pointer-events: auto;
	transform: ${({ isSticky, apiusSpace, isMenuOpen }) => `translateY(${isMenuOpen || apiusSpace ? '50%' : isSticky ? '0' : '50%'})`};
	transition: transform 0.3s ease-in-out;
	z-index: ${({ theme }) => theme.layers.third + 5};
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		transform: unset;
	}

	button {
		color: ${({ theme }) => theme.colors.white};

		&:after {
			background: ${({ theme }) => theme.colors.white};
		}

		&.underlineHover {
			&:before {
				background: ${({ theme }) => theme.colors.white};
			}
		}

		&#menu-close-button {
			&:before {
				background-color: ${({ theme }) => theme.colors.white};
			}
		}
	}

	&.color-scheme-light {
		button {
			color: ${({ theme }) => theme.colors.menuServicesColor};

			&:after {
				background: ${({ theme }) => theme.colors.menuServicesColor};
			}

			&.underlineHover {
				&:before {
					background: ${({ theme }) => theme.colors.menuServicesColor};
				}
			}

			&#menu-close-button {
				&:before {
					background-color: ${({ theme }) => theme.colors.menuServicesColor};
				}
			}
		}
	}
`;
const MenuButton = styled.button`
	align-items: center;
	border: unset;
	cursor: pointer;
	display: flex;
	font-size: 13px;
	font-weight: ${({ theme }) => theme.fontWeight.black};
	line-height: 16px;
	opacity: ${({ isActive, isMenuOpen }) => (isMenuOpen ? (isActive ? '1' : '0.5') : '1')};
	padding: unset;
	position: relative;
	transition: all 0.25s ease-in-out;

	&:after {
		align-self: end;
		border-radius: 50%;
		content: '';
		display: inline-flex;
		height: 4px;
		margin-left: 5px;
		transform: translateY(-2.5px);
		transition: all 0.25s ease-in-out;
		width: 4px;
	}

	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		font-size: 16px;
		line-height: 20px;
		&:after {
			transform: translateY(-5px);
		}
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		font-size: 22px;
		line-height: 27px;
		&:after {
			transform: translateY(-5px);
		}
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		font-size: 13px;
		line-height: 16px;

		&:after {
			transform: translateY(-2.5px);
		}
	}
`;

function Menu({ data = [], globals, pageContext, location }) {
	const isDesktopVersion = useIsDesktopVersion();
	const dispatch = useDispatch();
	const { scroll } = useLocomotiveScroll();
	const [moveDots, setMoveDots] = useState(false);
	const menu = useSelector((state) => state.menu);
	const menuIsOpen = useSelector((state) => state.menu.isOpen);
	const menuType = useSelector((state) => state.menu.type);
	const MenuRef = useRef(null);
	const colorScheme = useSelector((state) => state.colorScheme);
	const apiusSpace = useSelector((state) => state.apiusSpace);
	const menuData = useMemo(() => data.map((node) => node.treeChildren.sort((a, b) => a.position - b.position)), [data]);

	const menuContexts = useMemo(
		() => ({
			allServices: globals.menuAllServicesText || 'All services',
			buttonBig: globals.menuButtonBigText || 'Button big',
			buttonSmall: globals.menuButtonSmallText || 'Button small',
			contact: globals.menuContactUsHeader || 'Contact',
		}),
		[globals],
	);
	const narrowData = menuData?.[0] || [];
	const wideData = menuData?.[1] || [];
	const isTabletOrBigger = useMediaQuery(`(min-width: ${themeSettings.breakpoints.smallTablet}px)`);
	const [colorSchemeScroll, setColorSchemeScroll] = useState(colorScheme);
	const [isSticky, setIsSticky] = useState(false);
	const windowSize = useWindowSize();
	const handleIsSticky = (scroll) => {
		scroll.scroll.y > 60 ? setIsSticky(true) : setIsSticky(false);
	};
	const handleColorScheme = ({ currentElements }) => {
		if (apiusSpace.isOpen) return false;
		// if no light elements, set dark
		const lightElements = Object.keys(currentElements)
			.map((key) => currentElements[key])
			.filter((el) => el.el.classList.contains('light'));
		// if light elements exists, check if any are in the menu
		if (lightElements.length <= 0) return setColorSchemeScroll('dark');
		if (!MenuRef.current) return false;
		const menuBottom = MenuRef.current.offsetTop + MenuRef.current.offsetHeight;
		const isLight = lightElements.some((el) => {
			const bounds = el.el.getBoundingClientRect();
			return bounds.top < menuBottom && bounds.bottom > menuBottom;
		});
		return isLight ? setColorSchemeScroll('light') : setColorSchemeScroll('dark');
	};

	const handleHideSearch = (instance) => {
		const bottom = instance.limit.y - instance.scroll.y;
		const bar = document.getElementById('search-desktop-button');
		if (!bar) return;
		if (bottom <= 500) {
			bar.style.display = 'none';
		} else {
			bar.style.display = 'block';
		}
	};

	useEffect(() => {
		if (scroll) {
			if (isDesktopVersion) scroll.on('scroll', handleHideSearch);
		}
		return () => {
			if (scroll) {
				if (isDesktopVersion) scroll.off('scroll', handleHideSearch);
			}
		};
	}, [scroll, isDesktopVersion]);

	// set color scheme based on menu type
	useEffect(() => {
		switch (menuType) {
			case 'narrow':
				dispatch(setColorScheme('dark'));
				break;
			case 'wide':
				dispatch(setColorScheme('light'));
				break;
			default:
				dispatch(setColorScheme('dark'));
				break;
		}
		if (menuType !== menu.previousType) {
			dispatch(setPreviousMenuType(menuType));
		}
	}, [menuType, menuIsOpen]);

	// mount & unmount scroll event listeners
	useEffect(() => {
		if (scroll) {
			scroll.on('scroll', handleColorScheme);
			if (!isDesktopVersion) {
				scroll.on('scroll', handleIsSticky);
			}
		}
		return () => {
			if (scroll) {
				scroll.off('scroll', handleColorScheme);
				if (!isDesktopVersion) {
					scroll.off('scroll', handleIsSticky);
				}
			}
		};
	}, [scroll, isDesktopVersion, apiusSpace.isOpen]);

	// prevent changing color scheme on scroll when menu is open
	useEffect(() => {
		if (!menuIsOpen) {
			dispatch(setColorScheme(colorSchemeScroll));
		}
	}, [colorSchemeScroll, menuIsOpen]);

	// set color scheme on page load based on first element
	useEffect(() => {
		const firstElementOnPage = document.querySelector('#scroll-container').children[0];
		if (firstElementOnPage) {
			if (firstElementOnPage.classList.contains('light')) {
				setColorSchemeScroll('light');
			} else {
				setColorSchemeScroll('dark');
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		if (apiusSpace.isOpen) {
			setColorSchemeScroll('dark');
		} else if (scroll) {
			scroll.update();
		}
	}, [scroll, apiusSpace.isOpen]);

	// set vh and inner height variables
	useEffect(() => {
		if (!windowSize.height || !windowSize.width) return;
		const vh = windowSize.height * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		document.documentElement.style.setProperty('--inner-height', `${windowSize.height}px`);
	}, [windowSize]);

	useEffect(() => {
		if (isDesktopVersion) return;
		if (menuIsOpen) {
			document.documentElement.style.overflow = 'hidden';
		} else {
			document.documentElement.style.overflow = '';
		}
	}, [menuIsOpen, isDesktopVersion]);

	return (
		<Wrapper isSticky={isSticky} apiusSpace={apiusSpace.isOpen}>
			<Nav>
				<Logo
					locale={pageContext.locale}
					style={{
						flexGrow: 2,
					}}
					isSticky={isSticky}
					isMenuOpen={menu.isOpen}
				/>
				<ButtonsWrapper
					className={`color-scheme-${colorScheme}`}
					ref={MenuRef}
					id="menu-wrapper"
					isSticky={isSticky}
					isMenuOpen={menu.isOpen}
					apiusSpace={apiusSpace.isOpen}
				>
					<MenuButton
						isMenuOpen={menu.isOpen}
						onClick={() => {
							// handle menu button click
							if (menu.previousType === 'wide') {
								dispatch(setIsMenuOpen(!menu.isOpen));
							} else dispatch(setIsMenuOpen(true));
							dispatch(setMenuType('wide'));
						}}
						onPointerEnter={() => setMoveDots(true)}
						onPointerLeave={() => setMoveDots(false)}
						isActive={menuType === 'wide'}
						className="menu-button"
					>
						{isTabletOrBigger && (
							<ShufflingDots
								rows={3}
								columns={7}
								color={colorScheme === 'light' ? themeSettings.colors.menuServicesColor : themeSettings.colors.white}
								isHovered={moveDots}
							/>
						)}
						{data?.[1]?.title || ''}
					</MenuButton>
					<MenuButton
						isMenuOpen={menu.isOpen}
						onClick={() => {
							// handle menu button click
							if (menu.previousType === 'narrow') {
								dispatch(setIsMenuOpen(!menuIsOpen));
							} else dispatch(setIsMenuOpen(true));
							dispatch(setMenuType('narrow'));
						}}
						isSticky
						isActive={menuType === 'narrow'}
						className="menu-button underlineHover"
					>
						{data?.[0]?.title || ''}
					</MenuButton>
				</ButtonsWrapper>
			</Nav>

			<ExtendedMenu
				menuType={menuType}
				isMenuOpen={menuIsOpen}
				narrowData={narrowData}
				wideData={wideData}
				socials={globals.socialsItems}
				locale={pageContext.locale}
				otherLocale={pageContext.firstOtherLangSite}
				otherLangSlug={pageContext.otherLangPageSlug}
				menuContexts={menuContexts}
			/>
		</Wrapper>
	);
}

export default Menu;
