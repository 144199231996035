import React, { useEffect, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import JsCookies from 'js-cookie';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import ArrowButton from '../arrowButton/ArrowButton';
import SVGCookie from './SVGCookie';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Wrapper = styled.div`
	animation: ${fadeIn} 0.35s ease-in-out;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 20px;
	bottom: 15px;
	box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
	color: ${({ theme }) => theme.colors.navy};
	display: flex;
	flex-direction: column;
	left: 15px;
	padding: 60px 25px 25px 25px;
	position: fixed;
	row-gap: 13px;
	transition: max-height 0.35s ease-in-out;
	width: min(calc(100% - 30px), 563px);
	z-index: 101;

	.button-container {
		cursor: pointer;
	}

	&.extended {
		height: 100%;
		max-height: calc(100% - 150px);
		width: calc(100% - 30px);
	}

	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		&.extended {
			max-height: 411px;

			&.config {
				max-height: 693px;
			}
		}
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
		bottom: 30px;
		left: 70px;
		width: 563px;
		padding: 50px 42px;
		height: 100%;
		max-height: 293px;
		&.extended {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 751px;
			padding: 56px 45px;
			max-height: 474px;

			&.config {
				max-height: 693px;
			}
		}
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		left: 70px;
		bottom: 30px;
		width: 563px;
		padding: 50px 42px;
		height: 100%;
		max-height: 293px;
		&.extended {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 751px;
			padding: 56px 45px;
			max-height: 474px;

			&.config {
				max-height: 693px;
			}
		}
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 16px;
	@media (max-width: ${({ theme }) => `${theme.breakpoints.smallTablet - 1}px`}) {
		flex-direction: column;
	}
`;
const Header = styled.h1`
	font-size: 22px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		font-size: 28px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
		font-size: 38px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		font-size: 38px;
	}
`;

const Paragraph = styled.div`
	font-size: 14px;
	line-height: 24px;
`;

const Image = styled(SVGCookie)`
	background-color: white;
	border-radius: 50%;
	height: 80px;
	position: absolute;
	top: 0;
	transform: translateY(-30%);
	width: 80px;
`;

const ExtendedWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: end;
	row-gap: 43px;
	width: 100%;
`;

const Content = styled.div`
	animation: ${fadeIn} 0.5s ease-in-out;
	display: flex;
	flex-direction: column;
	gap: 32px;
	height: 100%;
	overflow: auto;
	position: relative;
	width: 100%;

	::-webkit-scrollbar {
		display: none;
	}
`;

const CookieWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 10px;
	position: relative;
	row-gap: 32px;
	width: 100%;

	label {
		display: flex;
		flex-direction: row;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		justify-content: space-between;
		pointer-events: none;
		position: relative;
		width: 100%;

		input {
			height: 26px;
			opacity: 0;
			pointer-events: auto;
			position: absolute;
			right: 0;
		}

		span {
			-webkit-transition: 0.4s;
			background-color: ${({ theme }) => theme.colors.navy};
			border-radius: 34px;
			bottom: 0;
			cursor: ${({ isDefault }) => (isDefault ? 'not-allowed' : 'pointer')};
			height: 26px;
			left: 0;
			pointer-events: auto;
			position: relative;
			right: 0;
			top: 0;
			transition: 0.4s;
			width: 54px;

			&:before {
				-webkit-transition: 0.4s;
				background-color: ${({ theme }) => theme.colors.white};
				border-radius: 50%;
				bottom: 4px;
				content: '';
				height: 18px;
				left: 4px;
				position: absolute;
				transition: 0.4s;
				width: 18px;
			}
		}

		input:checked + span {
			background-color: ${({ theme }) => theme.colors.green};
		}

		input:checked + span:before {
			-ms-transform: translateX(26px);
			-webkit-transform: translateX(26px);
			background-color: ${({ theme }) => theme.colors.white};
			transform: translateX(26px);
		}
	}
`;

const HeaderButton = styled.button`
	border: none;
	cursor: pointer;
	font-size: 24px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	padding: 0;
	position: relative;

	&:after {
		background-color: ${({ theme }) => theme.colors.green};
		bottom: 0;
		content: '';
		height: 1px;
		left: 0;
		position: absolute;
		transform: scaleX(0) translateY(2px);
		transform-origin: center;
		transition: transform 0.2s ease-in-out;
		width: 100%;
	}

	&.active {
		&:after {
			transform: scaleX(100%) translateY(2px);
		}
	}
`;

function Cookie({ title, description, id, isDefault = false, options, handleSwitch }) {
	return (
		<CookieWrapper isDefault={isDefault}>
			<label>
				{title}
				{isDefault ? (
					<input type="checkbox" defaultChecked disabled id={id} />
				) : (
					<input type="checkbox" id={id} checked={options[id]} onChange={(e) => handleSwitch(id, e.target.checked)} />
				)}
				<span className="slider round" />
			</label>
			<Paragraph dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
		</CookieWrapper>
	);
}

// number of days cookie expires after
const expires = 100;

function handleCookieSwitch(group, value) {
	switch (group) {
		case 'marketing':
			if (!value) return false;
			return JsCookies.set('gatsby-gdpr-facebook-pixel', value, { expires });
		case 'analytics':
			if (!value) return false;
			JsCookies.set('gatsby-gdpr-google-analytics', value, { expires });
			return JsCookies.set('gatsby-gdpr-google-tagmanager', value, { expires });
		default:
			return false;
	}
}

function Cookies({ locale }) {
	const { dataPL, dataEN } = useStaticQuery(graphql`
		query CookiesQuery {
			dataPL: datoCmsCookiesModel(locale: "pl") {
				acceptAllButtonText
				acceptButtonSmallText
				acceptButtonText
				backButtonSmallText
				backButtonText
				configButtonSmallText
				configButtonText
				essentialCookieDescription
				essentialCookieTitle
				generalHeaderText
				longDescription
				shortDescription
				title
				cookies {
					title
					description
					handle
				}
			}
			dataEN: datoCmsCookiesModel(locale: "en") {
				acceptAllButtonText
				acceptButtonSmallText
				acceptButtonText
				backButtonSmallText
				backButtonText
				configButtonSmallText
				configButtonText
				essentialCookieDescription
				essentialCookieTitle
				generalHeaderText
				longDescription
				shortDescription
				title
				cookies {
					title
					description
					handle
				}
			}
		}
	`);
	const data = useMemo(() => (locale === 'pl' ? { ...dataPL } : { ...dataEN }), [locale]);
	const {
		cookies,
		acceptAllButtonText,
		acceptButtonSmallText,
		acceptButtonText,
		backButtonSmallText,
		backButtonText,
		configButtonSmallText,
		configButtonText,
		essentialCookieDescription,
		essentialCookieTitle,
		generalHeaderText,
		longDescription,
		shortDescription,
		title,
	} = data;
	const [extended, setExtended] = useState(false);
	const [config, setConfig] = useState(false);
	const [options, setOptions] = useState(cookies.reduce((acc, cookie) => ({ [cookie.handle]: false, ...acc }), { essential: true }));
	const [hasConsent, setHasConsent] = useState(true);
	const location = useLocation();

	const setCookie = (cookieName, value) =>
		setOptions((prevState) => ({
			...prevState,
			[cookieName]: value,
		}));

	const handleAccept = (all = false) => {
		if (all) {
			Object.keys(options).forEach((cookie) => {
				setCookie(cookie, true);
				handleCookieSwitch(cookie, true);
			});
		} else {
			Object.keys(options).forEach((cookie) => {
				handleCookieSwitch(cookie, options[cookie]);
			});
		}
		JsCookies.set('hasConsent', true, { expires });
		setHasConsent(true);
		initializeAndTrack(location);
	};
	useEffect(() => {
		const cookieConsent = JsCookies.get('hasConsent');
		if (!cookieConsent) setHasConsent(false);
	}, []);

	if (hasConsent) return null;
	return !extended ? (
		<Wrapper>
			<Image />
			<Header>{title || ''}</Header>
			<Paragraph style={{ marginBottom: 15 }} dangerouslySetInnerHTML={getHtmlFromRedactorField(shortDescription)} />
			<ButtonWrapper>
				<ArrowButton
					smallText={configButtonSmallText || ''}
					text={configButtonText || ''}
					variant="secondary-navy"
					onClick={() => setExtended(true)}
				/>
				<ArrowButton
					smallText={acceptButtonSmallText || ''}
					text={acceptAllButtonText || ''}
					variant="primary-navy"
					onClick={handleAccept}
				/>
			</ButtonWrapper>
		</Wrapper>
	) : (
		<Wrapper className={`extended ${config ? 'config' : ''}`} key={extended}>
			<Image />
			<ExtendedWrapper>
				<div>
					<HeaderButton className={config ? '' : 'active'} onClick={() => setConfig(false)} style={{ marginRight: 16 }}>
						{generalHeaderText || ''}
					</HeaderButton>
					<HeaderButton className={config ? 'active' : ''} onClick={() => setConfig(true)}>
						{configButtonText || ''}
					</HeaderButton>
				</div>
				<Content key={config}>
					{!config ? (
						<Paragraph dangerouslySetInnerHTML={getHtmlFromRedactorField(longDescription)} />
					) : (
						<>
							<Cookie title={essentialCookieTitle || ''} description={essentialCookieDescription} id="essential" isDefault />
							{cookies.map((cookie) => (
								<Cookie
									key={cookie.handle}
									id={cookie.handle}
									title={cookie.title}
									description={cookie.description}
									options={options}
									handleSwitch={setCookie}
								/>
							))}
						</>
					)}
				</Content>

				<ButtonWrapper>
					<ArrowButton
						smallText={backButtonSmallText || ''}
						text={backButtonText || ''}
						variant="secondary-navy"
						onClick={() => {
							setConfig(false);
							setExtended(false);
						}}
					/>
					{!config ? (
						<ArrowButton
							smallText={configButtonSmallText || ''}
							text={configButtonText || ''}
							variant="secondary-navy"
							onClick={() => {
								setConfig(true);
							}}
						/>
					) : (
						<ArrowButton
							smallText={acceptButtonSmallText || ''}
							text={acceptButtonText || ''}
							variant="secondary-navy"
							onClick={() => {
								setConfig(false);
								setExtended(false);
								handleAccept();
							}}
						/>
					)}
					<ArrowButton
						smallText={acceptButtonSmallText || ''}
						text={acceptAllButtonText || ''}
						variant="primary-navy"
						onClick={handleAccept}
					/>
				</ButtonWrapper>
			</ExtendedWrapper>
		</Wrapper>
	);
}

export default Cookies;
