import { createSlice } from '@reduxjs/toolkit';

export const DNASlice = createSlice({
	name: 'DNA',
	initialState: {
		step: 1,
		category: '',
		service: '',
		type: 'home',
		hover: false,
		hide: false,
	},
	reducers: {
		setDNAStep: (state, action) => {
			state.step = action.payload;
		},
		setDNACategory: (state, action) => {
			state.category = action.payload;
		},
		setDNAService: (state, action) => {
			state.service = action.payload;
		},
		setDNAType: (state, action) => {
			state.type = action.payload;
		},
		setDNAHover: (state, action) => {
			state.hover = action.payload;
		},
		setDNAHide: (state, action) => {
			state.hide = action.payload;
		},
	},
});

export const { setDNAStep, setDNACategory, setDNAService, setDNAType, setDNAHover, setDNAHide } = DNASlice.actions;

export default DNASlice.reducer;
